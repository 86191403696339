function initGA() {
  const script = document.createElement('script')

  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_ID}`
  script.async = true

  document.body.append(script)
  ;(window as any).dataLayer = (window as any).dataLayer || []
  ;(window as any).gtag = function () {
    ;(window as any).dataLayer.push(arguments)
  }
  ;(window as any).gtag('js', new Date())
  ;(window as any).gtag('config', process.env.REACT_APP_GA_ID)
}

export function initTracker() {
  if (!!process.env.REACT_APP_GA_ID) {
    initGA()
  }
}

export function track(eventName: string, data: any) {
  if (!!process.env.REACT_APP_GA_ID) {
    ;(window as any).gtag('event', eventName, data)
  }
}
