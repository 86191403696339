import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

import hmacSHA256 from 'crypto-js/hmac-sha256'
import uniqWith from 'lodash/uniqWith'
import { useQueries, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { CouldBe } from 'src/types/main'

import { ToastContent } from '../components/Common/Toast'
import {
  batchFetchHistory,
  getDonationHistory,
  getDonationResultById,
  getLocalStorageDonationHistoryHash,
  getLocalStorageDonationWillClaimCoupon,
  getLocalStorageNonRedemptionPaymentIds,
  setLocalStorageDonationHistoryHash
} from '../services/donation'
import {
  getLocalStoragePollKeys,
  putNewKeyToLocalStoragePollKeys,
  setLocalStoragePollKeys
} from '../services/poll'
import { DonationResultQueryData } from '../types/api'
import { useModalContext } from './ModalContext'
import { useWeb3Context } from './Web3Context'

type PollContextType = {
  pollKeys: string[]
  addPollKey: (key: string) => void
}

const INITIAL_POLL_CONTEXT: PollContextType = {
  pollKeys: [],
  addPollKey: () => {
    throw new Error('addPollKey function must be overriden before use')
  }
}

const PollContext = createContext(INITIAL_POLL_CONTEXT)

const PollProvider = ({ children }: PropsWithChildren<{}>) => {
  const { account } = useWeb3Context()
  const { closeModal, openedModalStack, modalIsOpen } = useModalContext()

  const [pollKeys, setPollKeys] = useState<string[]>(INITIAL_POLL_CONTEXT.pollKeys)

  const addPollKey = useCallback((key: string) => {
    setPollKeys((prev) => [...prev, key])
    putNewKeyToLocalStoragePollKeys([key])
  }, [])

  const [donationHistoryHash, setDonationHistoryHash] = useState<string>()
  const [donationHistoryError, setDonationHistoryError] = useState<boolean>(false)
  const queryClient = useQueryClient()
  // const [donationHistory, setDonationHistory] = useState<DonationResultQueryData[]>([])

  const [accountForPoll, setAccountForPoll] = useState<CouldBe<string>>()
  useEffect(() => {
    const willClaimCoupon = getLocalStorageDonationWillClaimCoupon()
    if (willClaimCoupon) {
      if (Number(willClaimCoupon) === 1 && account) {
        return setAccountForPoll(account)
      } else if ((Number(willClaimCoupon) === 1 && !account) || Number(willClaimCoupon) === 0) {
        return setAccountForPoll(undefined)
      }
    }
  }, [account])

  const { data: donationHistory } = useQuery(
    ['donationHistory', accountForPoll],
    async () => {
      const history: DonationResultQueryData[] = []

      if (accountForPoll) {
        const donationHistory = await getDonationHistory(accountForPoll)

        history.push(...donationHistory)
      }

      const nonRedemptionPaymentIds = getLocalStorageNonRedemptionPaymentIds()

      if (nonRedemptionPaymentIds && nonRedemptionPaymentIds.length) {
        const nonRedemptionDonationHistory = await batchFetchHistory(nonRedemptionPaymentIds)
        history.push(...nonRedemptionDonationHistory)
      }

      return uniqWith(history, (a, b) => a.id === b.id)
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 86_400_000, // 24 hours, in ms
      onSuccess: (data) => {
        if (!process.env.REACT_APP_HISTORY_HASH_KEY) {
          console.error(
            'Unable to execute payment poll due to unfulfilled donation history requirement. Reason: missing history hash key.'
          )
          setDonationHistoryError(true)
          return
        }

        console.time('Time consumed for hashing whole history')
        const hashKey = process.env.REACT_APP_HISTORY_HASH_KEY
        const strigifiedHistory = JSON.stringify(data)
        const historyHash = hmacSHA256(strigifiedHistory, hashKey).toString()
        setDonationHistoryHash(historyHash)

        const existingLocalStorageHistoryHash = getLocalStorageDonationHistoryHash()
        if (!existingLocalStorageHistoryHash) {
          setLocalStorageDonationHistoryHash(historyHash)
        }
        console.timeEnd('Time consumed for hashing whole history')
      }
    }
  )

  useQueries(
    pollKeys.map((key) => ({
      queryKey: ['resultPoll', key],
      queryFn: () => {
        console.log(`[${key}] Fetching result`)
        return getDonationResultById(key)
      },
      refetchInterval: process.env.REACT_APP_ENV === 'production' ? 1000 : 10 * 1000,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      retry: true,
      retryDelay: process.env.REACT_APP_ENV === 'production' ? 3 * 1000 : 10 * 1000,
      onSuccess: (data: DonationResultQueryData) => {
        if ((data as any).message) {
          const errorMessage = (data as any).message

          /*if (errorMessage === ERROR_MESSAGE.REQUEST_DATABASE_LOCKED) {
            //
          } else {
            const _pollKeys = [...pollKeys]
            _pollKeys.splice(_pollKeys.indexOf(key), 1)
            setPollKeys(_pollKeys)
            setLocalStoragePollKeys(_pollKeys)
          }*/
          console.log(`${errorMessage}. Retrying...`)
          return
        }

        const now = new Date().getTime()
        const expiredTime = new Date(data.expired_at).getTime()
        const [paymentMethod, paymentTime] = data.description.split('|')

        if (data.status === 'success' || data.status === 'failed' || data.blocked_reasons) {
          console.log(`[${key}] Status: ${data.status}`)
          queryClient.removeQueries(['resultPoll', key])

          const clonedPollKeys = [...pollKeys]
          clonedPollKeys.splice(pollKeys.indexOf(key), 1)
          setPollKeys([...clonedPollKeys])
          setLocalStoragePollKeys([...clonedPollKeys])

          const willClaimCoupon = getLocalStorageDonationWillClaimCoupon()

          if (data.status === 'success') {
            toast(
              <ToastContent
                message={{
                  header: 'Payment succeed',
                  detail: `Your ${paymentMethod.trim()} payment on ${paymentTime.trim()} in the amount of SGD $${
                    data.donation_amount
                  } has been successfully recorded.${
                    willClaimCoupon && Number(willClaimCoupon) === 1
                      ? ' Your STAMP balance will be updated immediately.'
                      : ''
                  }`
                }}
              />,
              {
                toastId: data.tx_id,
                closeOnClick: false,
                autoClose: false,
                type: 'success'
              }
            )
          } else if (data.blocked_reasons) {
            toast(
              <ToastContent
                message={{
                  header: 'Payment blocked',
                  detail: `Your ${paymentMethod.trim()} payment on ${paymentTime.trim()} in the amount of SGD $${
                    data.donation_amount
                  } has been blocked. Reason: ${
                    data.blocked_reasons
                  }. Please create a new donation payment request and try again`
                }}
              />,
              {
                toastId: data.tx_id,
                closeOnClick: true,
                autoClose: false,
                type: 'error'
              }
            )
          } else if (data.status === 'failed') {
            const isExpired = expiredTime < now
            toast(
              <ToastContent
                message={{
                  header: 'Payment failed',
                  detail: `Your ${paymentMethod.trim()} payment on ${paymentTime.trim()} in the amount of SGD $${
                    data.donation_amount
                  } has failed${
                    isExpired ? ' due to expired waiting time' : ''
                  }. Please create a new donation payment request${
                    isExpired ? ' and make the transaction within the specified time' : ''
                  }.`
                }}
              />,
              {
                toastId: data.tx_id,
                closeOnClick: true,
                autoClose: false,
                type: 'error'
              }
            )
          }

          if (modalIsOpen && openedModalStack.includes(data.tx_id)) {
            closeModal(data.tx_id)
          }
        } else if (
          data.status === 'unpaid' &&
          paymentMethod.trim() === 'Cryptocurrency' &&
          expiredTime < now
        ) {
          console.log(`[${key}] Status: ${data.status}, expiration time exceeded`)
          queryClient.removeQueries(['resultPoll', key])

          const clonedPollKeys = [...pollKeys]
          clonedPollKeys.splice(pollKeys.indexOf(key), 1)
          setPollKeys([...clonedPollKeys])

          setLocalStoragePollKeys([...clonedPollKeys])

          toast(
            <ToastContent
              message={{
                header: 'Payment failed',
                detail: `Your ${paymentMethod.trim()} payment on ${paymentTime.trim()} in the amount of SGD $${
                  data.donation_amount
                } has failed due to expired waiting time. Please create a new donation payment request and make the transaction within the specified time.`
              }}
            />,
            {
              toastId: data.tx_id,
              closeOnClick: true,
              autoClose: false,
              type: 'error'
            }
          )

          if (modalIsOpen && openedModalStack.includes(data.tx_id)) {
            closeModal(data.tx_id)
          }
        }
      }
    }))
  )

  useEffect(() => {
    if (donationHistoryError || !(donationHistory && donationHistoryHash)) return
    console.time('Time consumed for setting up the poll')
    console.log('Initiate polling')

    const localStoragePollKeys = (getLocalStoragePollKeys() || '')
      .split(',')
      .filter((pollKey) => !!pollKey)
    const localStorageHistoryHash = getLocalStorageDonationHistoryHash()

    // Return early when the donation history hash on local storage is equal to the hash of
    // currently fetched donation history, and poll keys list is exist (meaning it's not null and
    // has value) on local storage. If this condition was fulfilled, we assume that history is
    // unchanged and user already have pending payment(s) to be polled.
    if (localStorageHistoryHash === donationHistoryHash && localStoragePollKeys.length) {
      setPollKeys(localStoragePollKeys)
      return
    }

    // If the above condition is unmet, then we start looking for unpaid payments from the
    // whole donation history data.
    const unpaidPaymentPollKeys = donationHistory.reduce<string[]>((keys, payment) => {
      const now = new Date().getTime()
      const paymentExpirationTime = new Date(payment.expired_at).getTime()

      // We only find payment that has unpaid status and the expiration time is in the future
      if (payment.status === 'unpaid' && paymentExpirationTime > now) {
        return [...keys, payment.id]
      }
      // Or we return the reduced keys collection if the above condition is unmet
      return keys
    }, [])

    // Here we might find that local storage poll keys list is exist but the donation history
    // has updated, thus we should combine unpaidPaymentPollKeys and localStoragePollKeys into a
    // single array and apply a unique filter so the array would only have unique poll keys.
    const combinedPollKeys = [...localStoragePollKeys, ...unpaidPaymentPollKeys].filter(
      (key, position, self) => self.indexOf(key) === position
    )

    // Finally we update in-app state and local storage with the latest poll keys and
    // donation history hash data
    setPollKeys(combinedPollKeys)
    setLocalStoragePollKeys(combinedPollKeys)
    setLocalStorageDonationHistoryHash(donationHistoryHash)
    console.log('Poll started')
    console.timeEnd('Time consumed for setting up the poll')
  }, [donationHistory, donationHistoryError, donationHistoryHash])

  return (
    <PollContext.Provider
      value={{
        pollKeys,
        addPollKey
      }}
    >
      {children}
    </PollContext.Provider>
  )
}

export default PollProvider

export const usePollContext = () => useContext(PollContext)

/*const StyledModalOpenerButton = styled(PrimaryButton)`
  margin-top: 8px;
`*/
